












































.input {
    letter-spacing: 1px;
}
